export default {
  state: {
    responseOrder: null,
    responseOrderDetail: null,
    responseOrderKitchen: null,
    responseOrderDetailKitchen: null,
    responseDeleteItem: null,
    responseDeleteItemKitchen: null,
    responseUpdate: null,
    responseUpdateKitchen: null,
    responseUpdateBulk: null,
    responseUpdateBulkKitchen: null,
    responsePay: null,
    responseNotification: null,
  },
  getters: {
    responseOrder: state => state.responseOrder,
    responseOrderDetail: state => state.responseOrderDetail,
    responseOrderKitchen: state => state.responseOrderKitchen,
    responseOrderDetailKitchen: state => state.responseOrderDetailKitchen,
    responseDeleteItem: state => state.responseDeleteItem,
    responseDeleteItemKitchen: state => state.responseDeleteItemKitchen,
    responseUpdate: state => state.responseUpdate,
    responseUpdateKitchen: state => state.responseUpdateKitchen,
    responseUpdateBulk: state => state.responseUpdateBulk,
    responseUpdateBulkKitchen: state => state.responseUpdateBulkKitchen,
    responsePay: state => state.responsePay,
    responseNotification: state => state.responseNotification
  },
  mutations: {
    setResponseOrder(state, payload) {
      state.responseOrder = payload
    },
    setResponseOrderDetail(state, payload) {
      state.responseOrderDetail = payload
    },
    setResponseDeleteItem(state, payload) {
      state.responseDeleteItem = payload
    },
    setResponseOrderKitchen(state, payload) {
      state.responseOrderKitchen = payload
    },
    setResponseOrderDetailKitchen(state, payload) {
      state.responseOrderDetailKitchen = payload
    },
    setResponseDeleteItemKitchen(state, payload) {
      state.responseDeleteItemKitchen = payload
    },
    setResponseUpdate(state, payload) {
      state.responseUpdate = payload
    },
    setResponseUpdateKitchen(state, payload) {
      state.responseUpdateKitchen = payload
    },
    setResponseUpdateBulk(state, payload) {
      state.responseUpdateBulk = payload
    },
    setResponseUpdateBulkKitchen(state, payload) {
      state.responseUpdateBulkKitchen = payload
    },
    setResponsePay(state, payload) {
      state.responsePay = payload
    },
    setResponseNotification(state, payload) {
      state.responseNotification = payload
    },
  },
  actions: {
  }
}
