import decode from "jwt-decode";
import apiPlace from "@/api/place.js";
/**
 * @param data - token
 */
export default {
  decode(token) {
    return decode(token);
  },
  async business() {
    const {
      data: { data },
    } = await apiPlace.placeBusiness({
      page: 1,
      per_page: 100,
      search: "",
      withBusiness: true,
    });
    return data;
  },
};
