export default {
  order: ["writeOrder"],
  mix: ["writeMix"],
  tag: ["writeTag"],
  // table: ["writeTable"],
  setting: ["writePOSConfiguration"],
  category: ["writeCategory"],
  kitchen: ["writeKitchen"],
  closingSales: ["closingSales"],
  // pay: ["writePaymentMethod"],
  // operation: ["writeOperationsPermit"],
  // printer: ["writePrinter"],
};
