import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ValidationProvider, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules'
import '@/assets/styles/global.css'
import VueHtmlToPaper from 'vue-html-to-paper';

import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

Vue.use(VueToast);
Vue.component('ValidationProvider', ValidationProvider);

Vue.config.productionTip = false

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
  });
  return formatter.format(value);
});
const options = {
  name: 'Steii',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css",
    "/styleprint.css"
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options);
new Vue({
  router,
  store,
  vuetify,
  VueHtmlToPaper,
  render: h => h(App)
}).$mount('#app')
