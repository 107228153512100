import axios from './index'

export default {
  placeBusiness(params) {
    return axios.get(`${process.env.VUE_APP_GESTION}/api/v1/place/table`, { params })
  },
  placeBusinessService(params) {
    return axios.get(`${process.env.VUE_APP_GESTION}/api/v1/business/service`, { params })
  },
  table(params) {
    return axios.get(`${process.env.VUE_APP_GESTION}/api/v1/place/table`, { params })
  },
  type: {
    getOneByID(id) {
      return axios.get(`${process.env.VUE_APP_GESTION}/api/v1/type/${id}`)
    },
    table(params) {
      return axios.get(`${process.env.VUE_APP_GESTION}/api/v1/type/table`, { params })
    },
  }
}