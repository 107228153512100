import '@fortawesome/fontawesome-free/css/all.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa' || 'md'
  },
  theme: {
    light: true,
    themes: {
      dark: {
        background: '#f7f7f7'
      },
      light: {
        steii: '#A88E68',
        primary: '#A88E68',
        success: '#53B58F',
        green: '#53B58F',
        red: '#F27372'
      },
    }
  }
});
