import axios from 'axios'
import vuex from "../store"

const { VUE_APP_API_URL } = process.env

axios.defaults.baseURL = VUE_APP_API_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'

axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  config.headers['Authorization'] = `Bearer ${token}`
  return config
})

axios.interceptors.response.use(res => res, err => {
  /**
   * @todo: Manjeador de errores comunes manejables
   */
  console.log('error interceptado', err)
  if (err.response.status === 403 && err.response.data.err === 'Invalid auth Token!') {
    localStorage.removeItem('token')
    localStorage.removeItem('employee')
    localStorage.removeItem('placeBusiness')
    location.reload()
    return 
  }
  throw err
})

export default axios