

export default {
  actions: {
    signOut() {
      localStorage.removeItem('token')
      localStorage.removeItem('employee')
      localStorage.removeItem('placeBusiness')
      $router.push('/auth/login')
    }
  }
}